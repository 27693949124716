<template>
    <div class="single-box-col-9 main-dashboard-establishments">
        <div class="md-layout headers">
            <div class="md-layout-item">
                <h2>Statistics</h2>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <h5>{{dateNow}}</h5>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
            </div>
        </div>
        <div class="md-layout" style="height: 48px;">

        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item">
                <md-card class="card-students">
                    <div class="md-layout" style="height: 100%;">
                        <div class="md-layout-item md-layout md-alignment-center-center">
                            <md-card-media>
                                <img class="dashboard-card-media" src="../../../assets/images/stats_students.svg">
                            </md-card-media>
                        </div>
                        <div class="md-layout-item md-layout md-alignment-center-center">
                            <md-card-header-text>
                                <div class="md-subhead">Total Students</div>
                                <div class="md-title">{{total_students}}</div>
                            </md-card-header-text>
                        </div>
                    </div>
                </md-card>
            </div>

            <div class="md-layout-item">
                <md-card class="card-companies">
                    <div class="md-layout" style="height: 100%;">
                        <div class="md-layout-item md-layout md-alignment-center-center">
                            <md-card-media>
                                <img class="dashboard-card-media" src="../../../assets/images/stats_companies.svg">
                            </md-card-media>
                        </div>
                        <div class="md-layout-item md-layout md-alignment-center-center">
                            <md-card-header-text>
                                <div class="md-subhead">Total Companies</div>
                                <div class="md-title">{{total_companies}}</div>
                            </md-card-header-text>
                        </div>
                    </div>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getUsers,
        getEstablishments
    } from "../../../core/firestore";
    import moment from "moment";

    export default {
        name: "Dashboard",
        data() {
            return {
                dateNow: null,
                total_students: 0,
                total_companies: 0,
            }
        },
        created() {
            let that = this;

            this.dateNow = moment().local().format("YYYY/MM/DD");

            getUsers(function (users) {
                that.total_students = users.length;
            }, null, null);

            getEstablishments(function(establishments) {
                that.total_companies = establishments.length;
            }, null, null);
        }
    }
</script>

<style scoped>
    h5 {
        color: darkgray;
    }

    .card-students {
        border-radius: 16px;
        width: 360px;
        height: 160px;
        color: white;
        background-color: #DB3346;
        margin: 10px;
    }

    .card-companies {
        border-radius: 16px;
        width: 360px;
        height: 160px;
        color: white;
        background-color: #DE9E2F;
        margin: 10px;
    }

    .dashboard-card-media {
        width: 100px;
        height: 100px;
    }

    .main-dashboard-establishments {
        padding: 50px;
    }

    .main-dashboard-establishments .headers {
        margin-bottom: 10px;
    }
</style>